import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "parent", 'children' ]

  openEntry(e) {
    e.stopPropagation()
    this.closeEntry()
    const children = e.currentTarget.children[1]
    children.style.display = 'block'
  }

  closeEntry() {
    this.childrenTargets.forEach((item) => item.style.display = 'none')
  }
}