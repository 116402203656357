import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "backDrop" ]

  addModal = (content) => {
    const modal = document.createElement('div')
    modal.setAttribute('data-modal', 'modal')
    modal.setAttribute('data-controller', 'modal')
    modal.setAttribute('data-modal-target', 'backDrop')
    modal.setAttribute('data-action', 'click->modal#closeBackDrop')
    modal.classList.add('custom-modal-backdrop', 'justify-content-center', 'align-items-center')
    modal.innerHTML = content
    document.body.appendChild(modal)
  }

  activeModal(e) {
    e.preventDefault()
    this.resetModal()
    let target = e.currentTarget.getAttribute('data-target')
    let sourceContent = document.querySelector(`#${target}`)
    this.addModal(sourceContent.innerHTML)
  }

  closeModal(e) {
    e.preventDefault()
    this.resetModal()
  }

  resetModal() {
    if (document.querySelectorAll('[data-modal="modal"]').length) {
      document.querySelectorAll('[data-modal="modal"]').forEach((item) => item.remove())
    }
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.closeModal(event)
    }
  }

  closeBackDrop(e) {
    e.stopPropagation()
    if (e.target == this.backDropTarget) {
      document.querySelectorAll('[data-modal="modal"]').forEach((item) => item.remove())
    }
  }
}