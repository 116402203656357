import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['schoolOption', 'schoolSubOption', 'studentOption', 'selectLanguageId', 'teacherSeletor']

  connect() {
    if (this.studentOptionTarget.checked) {
      $('.student-selector').prop("disabled", false);
    } else {
      this._closeStudentOpions()
      this.schoolSubOptionTargets.forEach(element => {
        element.disabled = false
        element.checked = false
      });
    }
  }

  ajaxTeacherIds(e) {
    e.stopPropagation()
    let selected_language_ids = this.selectLanguageIdTargets.filter(e => e.checked).map(e => e.value)
    $.ajax({
      type: 'GET',
      url: '/admin/teacher_options',
      data: { "language_ids": selected_language_ids },
      success: (data, status, xhr) => {
        $('.teacher_select_tag').html(data);
        $('.teacher-selector').select2();
      },
      error: (error) => {
        console.log('error response:', error);
      }
    })
  }

  selectAllTeacher(e) {
    e.stopPropagation()
    var options = Array.from(this.teacherSeletorTarget.children)

    if (e.target.checked) {
      console.log('t')
      options.forEach(element => {
        element.selected = true
      })
      $('.teacher-selector').select2();
    } else {
      console.log('f')
      options.forEach(element => {
        element.selected = false
      })
      $('.teacher-selector').val(null).trigger("change");
    }
  }

  resetOptions(e) {
    e.stopPropagation()
    if (e.target == this.schoolOptionTarget) {
      this._closeStudentOpions()
    }

    if (e.target == this.studentOptionTarget) {
      // 開 StudentOpion
      $('.student-selector').prop("disabled", false);
    }
  }

  _closeStudentOpions() {
    $('.student-selector').select2().val(null).trigger("change");
    $('.student-selector').prop("disabled", true);
  }
}